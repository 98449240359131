import React, { useState, useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    InputAdornment,
    TextField,
    Avatar,
    Typography,
    Pagination,
    Skeleton,
    Stack,
    Tooltip
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { TableColumn, TableData } from './type';
import {
    ListTableWrapper, AntSwitch,
} from './ListTable.styled';
import SearchIcon from '@mui/icons-material/Search';
import { truncateText } from '../../common/helper';

interface ListTableProps {
    tabValue: string;
    columns: TableColumn[];
    data: TableData[];
    actions: {
        icon: React.ReactNode;
        label: string;
        color: string;
        onClick: (item: TableData) => void;
    }[];
    itemsPerPage?: number;
    onActionClick?: (action: any, item: TableData) => void;
    isStatus?: boolean;
    timeout: number;
}

function ListTable({ tabValue, columns, data, actions, itemsPerPage = 10, onActionClick, isStatus= true, timeout }: ListTableProps) {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [searchPage,setSearchPage] = useState(1)

    const filteredData = isLoading
        ? new Array(itemsPerPage).fill(null)
        : data.filter(item => {
            return columns.some(column => {
                const columnAccessor = column?.accessor;
                const columnValue = item && columnAccessor ? item[columnAccessor] : undefined;

                if (columnValue !== undefined && columnValue !== null) {
                    const lowerCaseColumnValue = columnValue.toString().toLowerCase();
                    const lowerCaseSearchQuery = searchQuery.toLowerCase();
                    return lowerCaseColumnValue.includes(lowerCaseSearchQuery);
                }
                return false;
            });
        })

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleData = filteredData.slice(startIndex, endIndex);
    const totalRecords = filteredData?.length;

useEffect(() => {
    // Check if currentPage exceeds totalPages after filtering
    if (currentPage > totalPages && totalPages !== 0) {
        setCurrentPage(totalPages); // Set currentPage to the last page
    }

    // If there are no visibleData on the current page and currentPage > 1, go to the previous page
    if (visibleData.length === 0 && currentPage > 1) {
        setCurrentPage(currentPage-1);
    }

}, [filteredData, currentPage, totalPages, visibleData]);

// Set current page to searchPage when searchPage changes
useEffect(() => {
    setCurrentPage(searchPage);
}, [searchPage]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, timeout);

        return () => clearTimeout(timer);
    }, [timeout, currentPage]);

    return (
        <ListTableWrapper>
            <div className="list_table-main-table">
                <div className="table-row-item srch_position" style={{ border: 0 }}>
                    <Box className="actions">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton size="large">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </div>
                <TabContext value={tabValue}>
                    <TabPanel value={tabValue} className="override-tabpanel">
                        <div className="table-row-item-cell table-header-modify">
                            {columns.map(column => (
                                <Box key={column?.accessor} className="list_table-table-head">
                                    {column.header}
                                </Box>
                            ))}
                            {isStatus && (
                                <Box className="list_table-table-head list-table-status-head">
                                    Status
                                </Box>
                            )}
                            {Array.isArray(actions) && (
                                <Box className="list_table-table-head list-table-action-head">
                                    Actions
                                </Box>
                            )}
                        </div>
                        {isLoading ? (
                            Array.from({ length: itemsPerPage / 2 }).map((_, index) => (
                                <div className="table-row-item" key={index}>
                                    {columns.map((column) => (
                                        <Box className="list-list_table-box-wrap" key={column?.accessor}>
                                            <List>
                                                <ListItem className="list-list_table">
                                                    <Skeleton width={300} animation="wave" variant="text" />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    ))}
                                    {isStatus && (
                                        <Box className="list-list_table-box-wrap" key={"status"}>
                                            <List>
                                                <ListItem className="list-list_table">
                                                    <Skeleton width={150} animation="wave" variant="text" />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    )}
                                    {Array.isArray(actions) && (
                                        <Box className="list-list_table-box-wrap" key={"actions"}>
                                            <List>
                                                <ListItem className="list-list_table">
                                                    <Skeleton width={150} animation="wave" variant="text" />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    )}
                                </div>
                            ))
                        ) : (visibleData && visibleData?.length === 0) ? (
                            <p style={{ textAlign: 'center', fontWeight: 400 }}>No data to display</p>
                        ) : (
                            visibleData?.map((item, index) => (
                                <div className="table-row-item" key={index}>
                                    {columns?.map((column) => {
                                        const elem = item?.[column?.accessor] !== undefined ? truncateText(item?.[column?.accessor], 30) : ''
                                        const desgArray = item['designation'];
                                        const data = desgArray?.map((desg: { name: any; }) => desg?.name).join(', ');
                                                    
                                        return(
                                            <Box className="list-list_table-box-wrap" key={column?.accessor}>
                                            <List>
                                                <ListItem className="list-list_table">
                                                    {(column.type === "image" || column.type === "Image") ? (
                                                        <Avatar alt="Image" src={decodeURIComponent(item?.[column?.accessor])} />
                                                    ) : (column?.type === "html" || column?.type === "HTML") ? (
                                                        // Render HTML content
                                                                <div>
                                                                    {Array.isArray(elem) ? (
                                                                        elem.map((item, index) => (
                                                                            <div key={index}>
                                                                                <div dangerouslySetInnerHTML={{ __html: item }} />
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div dangerouslySetInnerHTML={{ __html: elem }} />
                                                                    )}
                                                                </div>) : (column?.accessor === 'designation') ? (
                                                                          <Tooltip title={data} arrow>
                                                                              <ListItemText
                                                                                  className="listIem-text"
                                                                                  primary={truncateText(data, 20)}
                                                                              />
                                                                          </Tooltip>                                                
                                                        ): (column?.accessor === 'name') ? (
                                                          <Tooltip title={item?.name} arrow>
                                                                <ListItemText
                                                                    className="listIem-text"
                                                                    primary={truncateText(item?.name, 20)}
                                                                />
                                                            </Tooltip>
                                                        ):
                                                        (column?.accessor === 'buHead') ? (      
                                                            <Tooltip title={item?.buHead} arrow>
                                                                <ListItemText
                                                                    className="listIem-text"
                                                                    primary={truncateText(item?.buHead, 20)}
                                                                />
                                                            </Tooltip>
                                                        ):
                                                        (column?.accessor === 'hrBPs') ? (                 
                                                            <Tooltip title={item?.hrBPs} arrow>
                                                                <ListItemText
                                                                    className="listIem-text"
                                                                    primary={truncateText(item?.hrBPs, 20)}
                                                                />
                                                            </Tooltip>
                                                        ):
                                                        (column?.accessor === 'responsibilites') ? (                 
                                                            <Tooltip title={
                                                                <div dangerouslySetInnerHTML={{ __html: item?.responsibilites }} />
                                                              } arrow>
                                                                <ListItemText
                                                                  className="listIem-text"
                                                                  primary={
                                                                    <div dangerouslySetInnerHTML={{ __html: String(truncateText(item?.responsibilites, 20)) }} />
                                                                  }
                                                                />
                                                              </Tooltip>
                                                        ):
                                                        (
                                                        // Render plain text
                                                        <ListItemText
                                                            className="listIem-text"
                                                            primary={item?.[column?.accessor] !== undefined ? truncateText(item?.[column?.accessor], 20) : ''}
                                                        />
                                                    )}
                                                </ListItem>
                                            </List>
                                        </Box>
                                        )
                                    }
                                      
                                    )}
                                    {isStatus && (
                                        <Box className="status-field-wrap" m={2}>
                                            <Stack direction="row" spacing={1} alignItems="center" style={{marginLeft:'15px'}}>
                                                {/* <Typography>Inactive</Typography> */}
                                                <AntSwitch
                                                    checked={item['status'] === 1}
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                    onClick={() => {
                                                        if (onActionClick) {
                                                            onActionClick({ label: "status" }, item);
                                                        }
                                                    }}
                                                />
                                                {/* <Typography >Active</Typography> */}
                                            </Stack>
                                        </Box>
                                    )}
                                    <Box className="actions-field-wrap">
                                        {actions.map((action, actionIndex) => (
                                            <span key={actionIndex}>
                                                <Tooltip title={action.label} arrow>
                                                    <IconButton
                                                        // color={action.color}
                                                        key={index}
                                                        size="large"
                                                        className={action.color ? action.color : 'primary-icon'}
                                                        onClick={() => {
                                                            if (onActionClick) {
                                                                onActionClick(action, item);
                                                            }
                                                        }}
                                                        
                                                    >
                                                        {action.icon}
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                        ))}
                                    </Box>
                                </div>
                            ))
                        )}
                    </TabPanel>
                </TabContext>
            </div>
            <Box mt={2} display="flex" justifyContent="flex-end">
                <div className='pgntn_box'>
                    <Typography className='showing_rslt' variant="body2" color="textSecondary">
                        Showing {visibleData && visibleData?.length} records out of {totalRecords} total.
                    </Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                        color="primary"
                        size="large"
                        className="pagination cs_pgntn"
                        siblingCount={1}
                        boundaryCount={1}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Box>
        </ListTableWrapper>
    );
}

export default ListTable;
