import * as React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 100;
function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItem, setActiveItem] = React.useState('/categories');

  const handleItemClick = (route: any) => {
    navigate(route);
    setActiveItem(route);
  };

  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname])

  const list = () => (
    <List sx={{ paddingTop: '15px' }}>
      {[
        { text: 'Categories', imageURL: 'categories-icon.svg', route: '/categories' },
        { text: 'Sub-Categories', imageURL: 'sub-categories-icon.svg', route: '/sub-categories' },
        { text: 'Badges', imageURL: 'badges-icon.svg', route: '/badges' },
        { text: 'Score Mapping', imageURL: 'score-mapping-icon.svg', route: '/score-mapping' },
        { text: 'KRA', imageURL: 'KRA-icon.svg', route: '/kra' },
        { text: 'Users', imageURL: 'users-icon.svg', route: '/users' },
        {text: 'BU', imageURL: 'BU-icon.svg', route: '/bu'},
      ].map(({ text, imageURL, route }) => (
        <ListItem
          sx={{
            alignContent: 'space-between',
            alignItems: 'center',
            color: '#fff',
            display: 'grid',
            justifyContent: 'center',
            borderLeft: activeItem.startsWith(route) ? '3px solid #DE4E35' : 'none',
          }}
          key={text}
          onClick={() => handleItemClick(route)}
        >
          <ListItemIcon sx={{ display: 'block', textAlign: 'center', cursor: 'pointer' }}>
            <img src={imageURL} alt="svg" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: 'center', cursor: 'pointer' }} primary={text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Drawer
      className="side-bar-container"
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: '#2559C3',
          paddingTop: '12px',
        },
      }}
    >
      <Toolbar />
      {list()}
    </Drawer>
  );
}

export default Sidebar;
